import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faBars, faEnvelope, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

library.add(
    faGithub,
    faBars,
    faEnvelope,
    faChevronLeft,
    faChevronRight
)
